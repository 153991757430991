import { Panel } from 'primereact/panel';
import Card from "../../components/card/card";
import "./styles.css"

function AcessoNegado() {
    return (
        <div className="d-flex justify-content-center">
            <Card className='d-flex row justify-content-center'>
                <Panel header="Acesso Negado">
                    <p className='m-0'>
                        Aparentemente você tentou executar uma operação a qual não tem permissão.<br />
                        Se você acha que esta mensagem está errada, por favor contate seu administrador.
                    </p>
                    <br />
                    <p><a href='/login'>Clique aqui para ir para página de login</a></p>
                </Panel>
            </Card>
        </div>
    )
}

export default AcessoNegado;