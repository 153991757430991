import decode from 'jwt-decode'
import LocalStorageService from './localstorageService'

export const USER_LOGGED = '@YORK:User'
export const TOKEN = '@YORK:Token'

export default class AuthService {
    state = {
        roles: [],
        isAutenticado: false,
        isLoading: true
    }

    static isAuthenticated(){
        const token = localStorage.getItem(TOKEN)
        if(token){
            const decodedToken = decode(token);
            const expiration = decodedToken.exp;
    
            const isTokenInvalido = Date.now() >= (expiration * 1000);
            if(!isTokenInvalido === true){
                return true;
            }
        }
        return false;
    }

    static logout(){
        LocalStorageService.removeItem(USER_LOGGED);
        LocalStorageService.removeItem(TOKEN);
        LocalStorageService.removeItem("email");
        LocalStorageService.removeItem("branch");
    };

    static login(usuario, token){
        LocalStorageService.addItem(USER_LOGGED, usuario);
        LocalStorageService.addItem(TOKEN, token);
    }

    static getUser(){
        const user = localStorage.getItem(TOKEN)
        if(user){
            const decoded = decode(user);
            return decoded ;
        }else{
            return null;
        } 
    }
}