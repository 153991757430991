import { useState } from 'react';
import { over } from 'stompjs';
import SockJS from 'sockjs-client/dist/sockjs';
import { BASE_URL } from './api';

export const useWebSocket = () => {
    const [stompClient, setStompClient] = useState(null);
    const [isConnect, setIsConnect] = useState(false);
    const token = localStorage.getItem('@YORK:Token');

    const connectWebSocket = () => {
        const url = `${BASE_URL}/ws`

        const socket = new SockJS(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        const stomp = over(socket);
        stomp.debug = false;
        setStompClient(stomp);

        stomp.connect({ token: token }, onConnect, onError);
    };

    const onError = (err) => {
        console.error(err);
    };

    const onConnect = () => {
        setIsConnect(true);
    };

    return {
        connectWebSocket,
        isConnect,
        stompClient
    };
};
