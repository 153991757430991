import 'bootswatch/dist/litera/bootstrap.css'
import 'primereact/resources/primereact.css'
import 'primeicons/primeicons.css'
import 'bootstrap-icons/font/bootstrap-icons.min.css'
import 'primereact/resources/themes/md-light-indigo/theme.css'

import './App.css';
import Router from './routes/router';
import { AuthProvider } from './context/authContext'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react'
import HabilitarNotificacao from './views/pagina-nao-encontrada/habilitarNotificacao'


function App() {
  useEffect(() => {
    if ('Notification' in window) {
      if (Notification.permission !== 'granted') {
        const requestPermission = () => {
          Notification.requestPermission().then(permission => {
            if (permission !== 'granted') {
              return (
                <HabilitarNotificacao />
              )
            }
          });
        };

        requestPermission();

        // Configura para tentar novamente a cada vez que a página é carregada
        const intervalId = setInterval(requestPermission, 1000); // Tenta a cada 1 minuto

        // Limpa o intervalo quando o componente é desmontado
        return () => clearInterval(intervalId);
      }
    } else {
    }
  }, []);

  return (
    <AuthProvider value={{ user: {}, authenticated: Boolean }}>
      <ToastContainer theme='colored' />
      <Router />
    </AuthProvider>
  );
}

export default App;
