import SFA1 from '../../../assets/sfa/sfa-1.jpg';
import SFA2 from '../../../assets/sfa/sfa-2.jpg';
import SFA3 from '../../../assets/sfa/sfa-3.jpg';
import SFA4 from '../../../assets/sfa/sfa-4.jpg';
import SFA5 from '../../../assets/sfa/sfa-5.jpg';
import SFA6 from '../../../assets/sfa/sfa-6.jpg';
import SFA7 from '../../../assets/sfa/sfa-7.jpg';
import SFA8 from '../../../assets/sfa/sfa-8.jpg';
import SFA_ERROR from '../../../assets/sfa/sfa-error.png';

function ArquivoCriptografado() {
    return (
        <div>
            <div className="image-example-error">
                <img src={SFA_ERROR} width={170} />
                <span className="my-1">Exemplo do erro de arquivo criptografado</span>
            </div>
            <div class="step my-2">
                <p className="step-subtitle">Para solucionar o erro de carga criptografado, você deve excluir todos os arquivos dentro da pasta localizado em:
                    <br />Armazenamento Interno {">>"} ControlInformática {">>"} CargaTxt {">>"} Imp
                    <br />Siga o passo à passo abaixo para maiores detalhes.
                </p>
            </div>
            <div class="step mt-4">
                <p class="step-title">1. Abrir o Aplicativo <span class="highlight">Files</span></p>
                <p className="step-subtitle">Procure pelo Aplicativo Files ou outro apk de Armazenamento de arquivos na tela inicial.</p>
                <img src={SFA1} width={50} />
            </div>

            <div class="step">
                <p class="step-title">2. Acessar o Armazenamento Interno</p>
                <p className="step-subtitle">Na tela principal do aplicativo <span class="highlight">Files</span>, selecione a opção <span class="highlight">Armazenamento Interno</span>.</p>
                <img src={SFA2} width={170} />
            </div>

            <div class="step">
                <p class="step-title">3. Navegar até a Pasta <span class="highlight">ControlInformática</span></p>
                <p className="step-subtitle">Procure a pasta chamada <span class="highlight">ControlInformática</span>. Toque para abrir essa pasta.</p>
                <img src={SFA3} width={170} />
            </div>

            <div class="step">
                <p class="step-title">4. Entrar na Pasta <span class="highlight">CargaTxt</span></p>
                <p className="step-subtitle">Localize e toque na pasta <span class="highlight">CargaTxt</span>.</p>
                <img src={SFA4} width={170} />
            </div>

            <div class="step">
                <p class="step-title">5. Acessar a Subpasta <span class="highlight">Imp</span></p>
                <p className="step-subtitle">Dentro da pasta CargaTxt, você encontrará a subpasta <span class="highlight">Imp</span>. Toque para abrir essa subpasta.</p>
                <img src={SFA5} width={170} />
            </div>

            <div class="step">
                <p class="step-title">6. Selecionar e Deletar os Arquivos</p>
                <p className="step-subtitle">Na pasta <span class="highlight">Imp</span>, selecione todos os arquivos presentes. Após selecionar os arquivos, toque no ícone de lixeira ou na opção <span class="highlight">Deletar</span> para remover todos os arquivos da pasta <span class="highlight">Imp</span>.</p>
                <img src={SFA6} width={170} />
            </div>

            <div class="step">
                <p class="step-title">7. Confirmar a Deleção e Reiniciar Processo de Recebimento de arquivo</p>
                <p className="step-subtitle">Confirme a deleção quando solicitado. Isso irá garantir que todos os arquivos na pasta  sejam removidos permanentemente.</p>
                <img src={SFA7} width={170} />
            </div>
            <div class="step">
                <p className="step-subtitle">Após seguir esses passos, você pode tentar receber o arquivo normalmente. Caso o erro persista, abra um chamado.</p>
            </div>
        </div>
    )
}

export default ArquivoCriptografado;