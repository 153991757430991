import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from "primereact/inputswitch";
import Logo from '../../../assets/york.png'
import Coca from '../../../assets/coca.png'
import Eise from '../../../assets/eise.svg'
import InputMask from 'react-input-mask';
import html2canvas from 'html2canvas';
import './assinatura.css'
import Form from '../../../components/form/form';
import InputForm from '../../../components/input/input';
import BranchService from '../../../services/resource/branchService';
import DropDown from '../../../components/dropdown/dropdown';
import { useAuth } from '../../../context/authContext';
import EmployeeService from '../../../services/resource/employeeService';

const initalValues = {
    name: '',
    fone: '',
    license: 6425,
    office: '',
    email: '',
    branch: 'Rua Waldemar Rocha, 2804 - Parque Ideal',
    state: 'Teresina, PI',
    layout: "1",
}

const initialLicense = {
    name: 'Teresina',
    code: 6425,
    address: 'Rua Waldemar Rocha, 2804',
    state: 'Piauí',
    city: 'Teresina',
    neighborhood: 'Parque Ideal'
}

function Assinatura() {
    const { user, authenticated } = useAuth();
    const [values, setValues] = useState(initalValues);
    const [icon, setIcon] = useState(true);
    const [autorizado, setAutorizado] = useState(true);
    const [isCel, setIsCel] = useState(true);
    const [branch, setBranch] = useState([]);
    const [license, setLicense] = useState(initialLicense);
    const branchService = new BranchService();
    const employeeService = new EmployeeService();

    useEffect(() => {
        branchService.findAll().then(response => {
            setBranch(response.data);
        })

        if (authenticated) {
            employeeService.findById(user.userid).then(response => {
                setValues(prev => ({
                    ...prev,
                    name: response.data.name,
                    email: response.data.email,
                    office: response.data.office,
                    fone: response.data.contact ? response.data.contact : ''
                }))
            })
        }

    }, []);

    useEffect(() => {
        if (values.license !== license.code) {
            const data = branch.find(x => x.code === values.license);
            setLicense(data);
        }

    }, [values.license]);

    const onChange = (e) => {
        const { name, value } = e.target;

        setValues({
            ...values,
            [name]: value,
        });

    };

    function gerarImagem() {
        document.querySelector('#assinatura').classList.add("assinatura-download");
        html2canvas(document.getElementById('assinatura')).then(function (canvas) {
            var name = 'Assinatura ' + values.name;
            let xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = function () {
                let a = document.createElement('a');
                a.href = window.URL.createObjectURL(xhr.response);
                a.download = name + '.png';
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();
                a.remove()
            };
            xhr.open('GET', canvas.toDataURL("image/png", 1.0));
            xhr.send();
        });
        document.querySelector('#assinatura').classList.remove("assinatura-download");
    }

    return (
        <>
            <div className="assinatura d-flex px-3">
                <div className='col-md-4 mb-3' >
                    <div className='row col-12'>
                        <div className='col-6' >
                            <Form label="Nome">
                                <InputForm
                                    type="text"
                                    label="Nome"
                                    name="name"
                                    maxLength={256}
                                    value={values.name}
                                    onChange={onChange}
                                />
                            </Form>
                        </div>
                        <div className='col-6'>
                            <Form label="Cargo">
                                <InputForm
                                    type="text"
                                    label="Cargo"
                                    name="office"
                                    maxLength={256}
                                    value={values.office}
                                    onChange={onChange}
                                />
                            </Form>
                        </div>
                        <div className='col-12'>
                            <Form label='E-mail'>
                                <InputForm
                                    type="text"
                                    label="E-mail"
                                    name="email"
                                    maxLength={256}
                                    value={values.email}
                                    onChange={onChange}
                                />
                            </Form>
                        </div>
                        <div className='col-6'>
                            <Form label="Contato">
                                <Dropdown
                                    id="DropPriority"
                                    name="priority"
                                    className="dropdown-custom"
                                    style={{ width: "100%" }}
                                    options={[
                                        { label: 'Celular', value: true },
                                        { label: 'Telefone', value: false }
                                    ]}
                                    value={isCel}
                                    onChange={e => setIsCel(e.value)}
                                />
                            </Form>
                        </div>
                        <div className='col-6 p-mask'>
                            {isCel ?
                                <Form label="Número">
                                    <InputMask mask="(99) 99999-9999" className="input-text-sm" type="tel" name='fone' value={values.fone} onChange={onChange} />
                                </Form> :
                                <Form label="Número">
                                    <InputMask mask="(99) 9999-9999" className="input-text-sm" type="tel" name='fone' value={values.fone} onChange={onChange} />
                                </Form>}
                        </div>
                        <div className='col-7'>
                            <Form label="Filial">
                                <DropDown
                                    id="DropBranchId"
                                    name="license"
                                    label="Filial"
                                    label1="name"
                                    optionValue="code"
                                    findBy="name"
                                    noFilter
                                    value={values.license}
                                    options={branch}
                                    onChange={onChange}
                                />
                            </Form>
                        </div>
                        <div className='col-5'>
                            <div className="d-flex mt-4">
                                <div>
                                    <InputSwitch checked={autorizado} onChange={(e) => setAutorizado(e.value)} />

                                </div>
                                <div className='ml-4'>
                                    <label className="">Distribuidor autorizado</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <button className="btn btn-primary mb-3" onClick={e => gerarImagem()}>Baixar assinatura</button>
                    </div>
                </div>
                <div className='col-12 col-md-8 container body' name="assinatura">
                    <div id='assinatura' className='assinatura'>
                        <table>
                            <tr className='container'>
                                <td style={{ fontSize: "17px", textAlign: "center", verticalAlign: 'bottom', paddingRight: '2px' }}>
                                    <img className='logo' src={Logo} style={{ marginRight: '3px', marginBottom: '23px' }} width={232} height={65} border="0" alt='Logo-York' />
                                    <br />
                                    <label style={{ fontWeight: '700', color: '#848484', fontFamily: 'calibri' }}>{autorizado ? "Distribuidor Autorizado" : "Distribuidor Logístico"}</label> <br />
                                    <img className='mr-2' src={Coca} width={78} border="0" alt='Logo-York' />
                                    <img className='' src={Eise} width={72} height={18} border="0" alt='Logo-York' />
                                </td>
                                {icon ? <td id="linha-vertical"></td> : ''}
                                <div className='imagemContain'>
                                    <td style={{
                                        fontFamily: 'arial, verdana', fontSize: "16.5px", height: '180px', verticalAlign: 'bottom'
                                    }}>
                                        <div className='info'>
                                            <div className='name text-center'>

                                                <strong className='text-center'>{values.name}</strong>
                                                <br />
                                                <strong></strong><i > {values.office}</i>
                                                <br />
                                            </div>
                                            <strong >
                                                {icon ? <i className='bi bi-telephone-fill' height={16} alt="telefone" /> : 'Telefone: '}
                                            </strong>
                                            <label > (86) 3236-4215 {values.fone !== "" ? " | " + values.fone : ""}
                                            </label>
                                            <br />
                                            <strong>{icon ? <i className='bi bi-envelope-at-fill' /> : 'E-mail:'}</strong>
                                            <label>{values.email}</label>
                                            <br />
                                            <strong>{icon ? <i className='bi bi-globe' /> : 'Site: '} </strong>
                                            <label>www.distribuidorayork.com.br</label>
                                            <br />
                                            <div >
                                                <strong >{icon ? <i className='bi bi-geo-alt-fill' /> : 'Endereço: '}</strong>
                                                <label> {license.address} - {license.neighborhood}</label>
                                                <br />
                                                <label style={icon ? { marginLeft: '23px' } : { marginLeft: '80px' }}>{license.city}, {license.state}</label>
                                            </div>
                                            <br />
                                        </div>
                                    </td>
                                </div>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Assinatura;