import React from 'react';
import { useAuth } from '../../context/authContext';
import LOGO from '../../assets/york.png'
import './styles.css';

function NavBar() {
    const { authenticated, user } = useAuth();

    const goTo = (e) => {
        const id = e.target.id;
        window.location.href = `/${id}`;
    }

    return (
        <nav className='navbar'>
            <input type="checkbox" id="check" name='check' />
            <label id='checkbtn' htmlFor="check" className="checkbtn">
                <i className="fas fa-bars"></i>
            </label>
            <a href="/suporte" className="logo-nav"><img src={LOGO} width={140} height={41} alt='Logo York' /></a>
            <ul>
                {authenticated ?
                    <>
                        <NavItem id='suporte/solicitacao' label='Nova solicitação' onClick={goTo} />
                        <NavItem id='suporte/minhas-solicitacoes/' label='Minhas Solicitações' onClick={goTo} />
                        {window.innerWidth > 800 ?
                            <NavItem id='suporte/dados' label={`Olá, ${user.username.split(' ')[0]}`} icon="bi bi-person-circle" onClick={goTo} />
                            :
                            <NavItem id='suporte/dados' label='Configurações' icon='bi bi-gear' onClick={goTo} />
                        }
                    </>
                    :
                    <>
                        <NavItem id='suporte/login' label='Fazer Login' onClick={goTo} />
                    </>
                }
            </ul>
        </nav>
    )
}

export default NavBar;

const NavItem = ({ id, icon, label, onClick }) => (
    <li>
        <label
            id={id}
            htmlFor="check"
            className={window.location.pathname.replace('/', '') === id ? 'active' : ''}
            onClick={onClick}
        >
            {label} <i       id={id} style={{fontSize:'18px'}} className={icon} />
        </label>
    </li>
);
