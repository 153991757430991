import axios from 'axios';

const port = window.location.hostname !== "localhost" ? ":8081" : ":8080";
const url = "http://" + window.location.hostname + port;

//export const BASE_URL = process.env.REACT_APP_APP_BACKEND_URL ?? url;
export const BASE_URL = 'https://vps54476.publiccloud.com.br';

const api = axios.create({
  baseURL: BASE_URL
});

const apiFile = axios.create({
  baseURL: BASE_URL,
  responseType: "blob"
});

class ApiService {

  constructor(apiurl) {
    this.apiurl = apiurl;
  }

  post(url, obj) {
    const requestUrl = `${this.apiurl}${url}`
    return api.post(requestUrl, obj);
  }

  put(url, obj) {
    const requestUrl = `${this.apiurl}${url}`
    return api.put(requestUrl, obj);
  }

  delete(url) {
    const requestUrl = `${this.apiurl}${url}`
    return api.delete(requestUrl)
  }

  get(url) {
    const requestUrl = `${this.apiurl}${url}`
    return api.get(requestUrl)
  }

  async getFile(url) {
    try {
      const response = await apiFile.get(`${this.apiurl}${url}`);
      return response;
    } catch (error) {
      if (error.response && error.response.data instanceof Blob) {
        const reader = new FileReader();
        reader.readAsText(error.response.data);

        return new Promise((resolve, reject) => {
          reader.onload = () => {
            try {
              const errorData = JSON.parse(reader.result);
              if (errorData && errorData.message) {
                reject(errorData);
              } else {
                reject(error);
              }
            } catch (parseError) {
              reject(error);
            }
          };

          reader.onerror = () => {
            reject(error);
          };
        });
      } else {
        return Promise.reject(error);
      }
    }
  }
}

api.interceptors.request.use(config => {
  const token = localStorage.getItem('@YORK:Token') || '';

  if (!token) return config;

  if (config?.headers) {
    config.headers = { Authorization: `Bearer ${token}` }
  }
  return config;
});

apiFile.interceptors.request.use(config => {
  const token = localStorage.getItem('@YORK:Token') || '';

  if (!token) return config;

  if (config?.headers) {
    config.headers = { Authorization: `Bearer ${token}` }
  }
  return config;
});



export default ApiService;