
import React from "react";
import LOGO from '../../assets/York-logo-fundobranco.jpg'

function HomeYork() {
    return (
        <div className="home-york container">
            <div className="d-flex justify-content-center">
                <img src={LOGO} width="350" alt="Texto alternativo" title="York" />
            </div>
            <div className="d-flex justify-content-center my-2">
                <label style={{ width: '70%', fontSize: '18px', color: '#787F85', textAlign: 'center' }}>
                    Somos uma Empresa Distribuidora de Bebidas que atua há 41 anos no mercado distribuindo com exclusividade os produtos fabricados pela SOLAR BR, franqueado Coca-Cola. Atualmente atuamos nos Estados do Piauí e Maranhão.
                </label>
            </div>
            <div className="d-flex justify-content-center gap-2 my-3">
                <a className="icons" href="https://wa.me/558632364215" target="_blank" rel="noopener noreferrer"><i className="bi bi-whatsapp" /></a>
                <a className="icons" href="https://www.linkedin.com/company/york-distribuidora-ltda" target="_blank" rel="noopener noreferrer"><i className="bi bi-linkedin" /></a>
                <a className="icons" href="https://instagram.com/yorkdistribuidora?utm_medium=copy_link" target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram" /></a>
            </div>
            <footer className="footer-york">
                DISTRIBUIDORA YORK LTDA | CNPJ: 07.230.451/0001-64
            </footer>
        </div>
    )
}

export default HomeYork;