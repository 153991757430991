
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import EmployeeService from "../../services/resource/employeeService";
import Form from "../../components/form/form";
import LOGO from '../../assets/york.png'
import Card from "../../components/card/card";
import InputForm from "../../components/input/input";
import './styles.css'
import { toast } from 'react-toastify';

function EsqueceuSenha() {
    const service = new EmployeeService();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const login = (event) => {
        event.preventDefault();

        service.sendResetPassword(email).then(() => {
            navigate('/suporte/login');
            toast.success("Foi enviado um e-mail com instruções para alteração de senha")
        }).catch((error) => {
            setError(error.response.data.message)
        })
    };

    useEffect(() => {
        setError('')
    }, [email])

    return (
        <div className="login">
            <Card className="login-card">
                <div className="d-flex justify-content-center mb-3">
                <img className="logo" src={LOGO} alt='LOGO YORK' height={53}  width={180} />
                </div>
                <div className="col-lg-12">
                    <form onSubmit={login}>
                        <Form>
                            <InputForm
                                id="email"
                                type="text"
                                label="Informe o e-mail cadastrado"
                                name="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)} />
                        </Form>
                        <label className="i-error mt-3">{error}</label>
                        <div className="d-flex justify-content-between">
                            <button type="button" className="btn btn-secondary mt-4" style={{ minWidth: "49%" }} onClick={() => navigate('/suporte/login')}>
                                VOLTAR
                            </button>
                            <button type="submit" className="btn btn-primary mt-4" style={{ minWidth: "49%" }}>
                            RECUPERAR SENHA
                        </button>
                        </div>
                    </form>
                </div>
            </Card>
        </div>
    )

}

export default EsqueceuSenha;