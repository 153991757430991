import ApiService from "../api";

export default class ServiceTypeService extends ApiService {

    constructor(){
        super('/api/services/type')
    }

    save(called){
        return this.post('/', called);
    }

    update(called){
        return this.put(`/${called.id}`, called);
    }

    del(id){
        return this.delete(`/${id}`)
    }

    findById(id){
        return this.get(`/${id}`)
    }

    list(){
        return this.get(`/`)
    }

    consult(filter){
        let params = `?description=${filter.title}`
        
        return this.get(params);
    }

}