import LOGO from '../../assets/chamado.jpg';
import Card from "../card/card";
import './styles.css';

function Sidenav(props) {
    return (
        <>
            {window.innerWidth > 800 &&
                <>
                    <div className="col-md-2"></div>
                    <div className="col-md-3 mb-2">
                        <Card
                            className="d-flex align-items-center mat-elevation-z8 px-3"
                            title={
                                <div className="card-image">
                                    <img src={props.img} alt="Designed by Freepik" width={130} height={120} />
                                </div>
                            }>
                            <div className="d-flex justify-content-start wrap-reverse">
                                <div className="card-info">
                                    <h6>{props.title}</h6>
                                    <label>{props.subtitle}</label>
                                </div>
                            </div>
                        </Card>
                    </div>
                </>
            }
        </>

    )
}
export default Sidenav;