import { Panel } from 'primereact/panel';
import Card from "../../components/card/card";
import "./styles.css"

function PaginaNaoEncontrada() {
    return (
        <div className="d-flex justify-content-center">
            <Card className='d-flex row justify-content-center'>
                <Panel header="Not Found">
                    <p className='m-0'>
                    Nenhuma página foi encontrada para o endereço acessado.
                    </p>
                    <br />
                    <p><a href='/'>Clique aqui para ir para página inicial</a></p>
                </Panel>
            </Card>
        </div>
    )
}

export default PaginaNaoEncontrada;