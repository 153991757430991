import ApiService from "../api";

export default class EmployeeService extends ApiService {

    constructor(){
        super('/api/employees')
    }

    findId(id){
        return this.get(`/data/${id}`)
    }

    findById(id){
        return this.get(`/${id}`)
    }

    findAll(){
        return this.get(`/`)
    }

    findAllEmployeeActive(){
        return this.get(`/actives`)
    }

    signup(credentials){
        return this.post(`/signup`, credentials)
    }
    
    updateData(id, employee){
        return this.put(`/data/${id}`, employee)
    }

    updateContact(employee){
        return this.put(`/update/contact`, employee)
    }

    authenticate(credentials){
        return this.post(`/authenticate`, credentials)
    }

    updatePassword(password){
        return this.post(`/update_password`, password)
    }

    resetPassword(password){
        return this.post(`/password/reset`, password)
    }

    sendResetPassword(email){
        return this.post(`/password/request/reset?email=${email}`)
    }
}