import React from "react";
import './styles.css'
import { Dropdown } from 'primereact/dropdown';

function DropDown(props) {

    const optionItem = (option) => {
        return (
            <div className="d-flex">
                {props.icon &&
                    <div className="dropdown-form-icon">
                        <i className={props.icon} />
                    </div>
                }
                {props.img &&
                    <div className="dropdown-form-icon">
                        <img src={option.image} width='20' />
                    </div>
                }
                <div>
                    <div>
                        {option[props.label1]}
                    </div>
                    <div>
                        {option[props.label2]}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <label className={"input-label " + (props.invalid && "i-error")} htmlFor={props.htmlFor}>{props.label} {props.optional ? <i className="text-secondary"> Opcional</i> : null}</label>
            <Dropdown
                id={props.id}
                name={props.name}
                value={props.value}
                options={props.options}
                className={"dropdown-custom " + (props.invalid && "p-invalid")}
                onChange={props.onChange}
                optionLabel={props.findBy}
                optionValue={props.optionValue}
                filter={props.noFilter === true ? false : true}
                showClear={props.showClear}
                filterBy={props.filterBy}
                placeholder={props.placeholder}
                filterPlaceholder={props.placeholderFilter}
                itemTemplate={optionItem}
                onBlur={props.onBlur}
                required={props.required}
                onFocus={props.onFocus}
                disabled={props.disabled}
            />
        </>
    )

}

export default DropDown;