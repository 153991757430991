import React from 'react';

function Footer() {
    return (
        <footer className='fixed-bottom text-center text-secondary py-1' style={{ zIndex: '-1' }}>
            Copyright © 2024 - Versão BETA
        </footer>
    )
}

export default Footer;

