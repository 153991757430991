import AuthService from '../services/resource/authService';
import LocalStorageService from '../services/resource/localstorageService'
import { createContext, useContext, useState, useEffect } from "react";

export const USER_LOGGED = '@YORK:User'
export const TOKEN = '@YORK:Token'

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState(() => {
        setAuthenticated(AuthService.isAuthenticated);
        return AuthService.getUser()
    });

    useEffect(() => {
        if (authenticated === false) {
            LocalStorageService.removeItem(USER_LOGGED);
            LocalStorageService.removeItem(TOKEN);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const login = (user, token) => {
        LocalStorageService.addItem(USER_LOGGED, user)
        localStorage.setItem(TOKEN, token)
        setUser(AuthService.getUser)
        setAuthenticated(true)
    }

    const logout = () => {
        setAuthenticated(false);
        AuthService.logout();
        setUser(null)
    }

    return (
        <AuthContext.Provider value={{ user, login, logout, authenticated }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('Contexto não encontrado')
    }

    return context;
}