import React from "react";
import './styles.css'
import { InputText } from "primereact/inputtext";

function InputForm(props) {
    return (
        <>
            <label className={props.invalid ? "input-label i-error" : "input-label"}>{props.label}
                {props.optional ? <i className="text-secondary"> Opcional</i> : null}
            </label>
            <InputText
                style={props.style}
                type={props.type}
                onKeyPress={props.onKeyPress}
                min={props.min}
                max={props.max}
                maxLength={props.maxLength}
                className={props.invalid ? "p-invalid input-text-sm" : "input-text-sm "}
                id={props.id}
                onBlur={props.onBlur}
                disabled={props.disabled}
                placeholder={props.placeholder}
                value={props.value}
                name={props.name}
                pattern={props.pattern}
                onChange={props.onChange}
                autoComplete={props.autoComplete}
                required={props.required}
                onInput={props.onInput} />
            <label className="input-float">{props.val}</label>
        </>
    )
}

export default InputForm;