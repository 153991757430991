import React from 'react';
import './styles.css'

class Card extends React.Component {
    render() {
        return (
            <div style={this.props.style} className={'card md-3 mat-elevation-z6 ' + this.props.className}>
                {this.props.title !== undefined ?
                    <div className='card-header'>
                        {this.props.title}
                    </div>
                    : null
                }
                <div className={!this.props.noPadding ? 'p-1' : ''}>
                    {this.props.children}
                </div>
                {this.props.footer !== undefined ?
                    <div className='card-footer'>
                        {this.props.footer}
                    </div>
                    : null
                }
            </div>
        )
    }

}

export default Card;