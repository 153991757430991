import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { InputMask } from "primereact/inputmask";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from 'primereact/fileupload';
import { toast } from 'react-toastify';

import Card from "../../components/card/card";
import InputForm from "../../components/input/input";
import Form from "../../components/form/form";
import DropDown from "../../components/dropdown/dropdown";
import CalledService from "../../services/resource/calledService";
import ServiceTypeService from "../../services/resource/serviceType";
import BranchService from "../../services/resource/branchService";
import EmployeeService from "../../services/resource/employeeService";
import ImagemChamado from '../../assets/chamado.jpg';

import { optionsPriority } from "../../utils/options";

import * as Yup from "yup";

import "./styles.css";
import { useAuth } from "../../context/authContext";
import Sidenav from "../../components/sidenav/sidenav";
import LoadingPage from "../loading/loading";
import ArquivoCriptografado from "./solucoes/ArquivoCriptografado";
import Assinatura from "./solucoes/AssinaturaEmail";
import useCommentWebSocket from "../../utils/useCommentWebSocket ";
import HabilitarNotificacao from "../pagina-nao-encontrada/habilitarNotificacao";
import LocalStorageService from "../../services/resource/localstorageService";


const initialValues = {
    title: "",
    description: "",
    requesterEmail: null,
    serviceTypeId: null,
    priority: 0,
    status: 0,
    branchId: null,
    attributes: {},
    sendEmail: true,
}

const initalCalled = {
    assigneeEmail: "",
    assigneeName: "",
    attachments: [],
    attributes: {},
    branchCode: "",
    branchName: "",
    comments: [],
    creationDate: "",
    description: "",
    id: 0,
    priority: "",
    requesterEmail: "",
    requesterName: "",
    serviceType: {},
    serviceTypeDescription: "",
    solutionApplied: "",
    status: "",
    title: "",
    updateDate: "",
    userEmail: "",
    userId: 0,
    userName: "",
    sendEmail: true,
}
const initialEmployee = {
    id: null,
    name: "",
    email: "",
    office: "Cargo",
    contact: '89 99999-9999',
    branchName: "",
    branchId: 1,
    sendEmail: false,
    active: true
}

function AberturaChamado() {
    useCommentWebSocket();

    const calledService = new CalledService();
    const serviceTypeService = new ServiceTypeService();
    const employeeService = new EmployeeService();
    const branchService = new BranchService();
    const getLocalEmail = localStorage.getItem('email');
    const getLocalBranch = localStorage.getItem('branch');
    const { authenticated, user } = useAuth();

    const [serviceType, setServiceType] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [employee, setEmployee] = useState(initialEmployee);
    const [contact, setContact] = useState('');
    const [office, setOffice] = useState('');
    const [branches, setBranches] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dialogArquivoCriptogrado, setDialogArquivoCriptografado] = useState(false);
    const [dialogAssinatura, setDialogAssinatura] = useState(false);
    const [isRequestOpen, setIsRequestOpen] = useState(false);
    const [step, setStep] = useState(3);
    const [called, setCalled] = useState(initalCalled);
    const [sfa, setSFA] = useState('');
    const [assinatura, setAssinatura] = useState('');
    const fileUploadRef = useRef(null);
    const canvasRef = useRef(null);

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            title: Yup.string().required("Campo obrigatório"),
            description: Yup.string().required("Campo obrigatório"),
            requesterEmail: Yup.string().email().required("Campo obrigatório"),
            serviceTypeId: Yup.string().required("Campo obrigatório"),
            priority: Yup.string().required("Campo obrigatório"),
            status: Yup.string().required("Campo obrigatório"),
            assigneeId: Yup.string().required("Campo obrigatório"),
            branchId: Yup.string().required("Campo obrigatório"),
        })
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        formik.setValues(prev => ({
            ...prev,
            attributes: {
                ...prev.attributes,
                [name]: value
            }
        }));
    };

    useEffect(() => {
        if (authenticated) {
            setStep(1);
            employeeService.findById(user.userid).then(response => {
                const data = response.data
                formik.setValues(prev => ({
                    ...prev,
                    branchId: data.branchId,
                    requesterEmail: data.email,
                    userId: data.email,
                }));

                if ((data.contact === null) || (data.office === null) === false) {
                    setContact(response.data.contact);
                    setOffice(response.data.office);
                    setEmployee(response.data);
                }
            })
        } else {
            setStep(0);

            employeeService.findAllEmployeeActive().then(response => {
                setEmployees(response.data);
            }).catch(() => toast.error("Erro ao buscar lista de funcionários"));

            branchService.findAll().then(response => {
                setBranches(response.data);
            }).catch(() => toast.error("Erro ao buscar lista de filiais"))
        }

        fetchServicesType();

        if (getLocalEmail !== null) {
            if (!authenticated) {
                formik.values.branchId = Number(getLocalBranch);
                formik.values.requesterEmail = getLocalEmail;
            };
        }
    }, []);
    useEffect(() => {
        formik.setValues(prev => ({
            ...prev,
            attributes: {}
        }));

        if (formik.values.serviceTypeId === assinatura.id) {
            setDialogAssinatura(true)
        }
    }, [formik.values.serviceTypeId]);

    const fetchServicesType = async () => {
        try {
            const response = await serviceTypeService.list();
            setServiceType(response.data);
            setTimeout(() => {
                setLoading(false);
            }, 400);
            setSFA(response.data.find(x => x.description.includes('SFA')))
            setAssinatura(response.data.find(x => x.description.includes('Assinat')))
        } catch (error) {
            console.error(error);
            toast.error("Erro ao buscar lista de serviços")
        }
    }

    const renderInput = () => {
        const data = serviceType.find(obj => obj.id === formik.values.serviceTypeId);

        if (!data) return null;

        return Object.entries(data.attributes)
            .sort((a, b) => {
                const labelA = a[1].split('___')[1] || '';
                const labelB = b[1].split('___')[1] || '';
                return labelA.localeCompare(labelB);
            })
            .map(([key, value]) => {
                const [type, label] = value.split('___');
                if (type === "checkbox") {
                    return (
                        <div key={key} className="col-md-12">
                            <Form id={key}>
                                <div className="d-flex">
                                    <Checkbox
                                        onChange={formik.handleChange}
                                        name={`attributes.${value}`}
                                        checked={formik.values.attributes[value] || false}
                                    />
                                    <label htmlFor={key} className="input-label">
                                        {label || key}
                                    </label>
                                </div>
                            </Form>
                        </div>
                    );
                } else {
                    return (
                        <div key={key} className="col-md-12">
                            <Form id={key}>
                                <InputForm
                                    autoComplete="new-password"
                                    id={key}
                                    type={type || 'text'} // Fallback para 'text' caso 'type' seja undefined
                                    label={label || key} // Usa 'key' como fallback caso 'label' seja undefined
                                    name={value}
                                    maxLength={80}
                                    value={formik.values.attributes[value] || ''}
                                    onChange={handleInputChange}
                                />
                            </Form>
                        </div>
                    );
                }
            });
    }

    const handleFileSelect = (event) => {
        const files = Array.from(event.files);
        const newFiles = [];
        const resizePromises = files.map(data => {
            return new Promise((resolve, reject) => {
                if (data.type.startsWith("image/")) {
                    resizeImage(data, 1024, 768, (blob) => {
                        const newFile = new File([blob], data.name, {
                            type: data.type,
                        });
                        newFiles.push(newFile);
                        resolve();
                    });
                } else {
                    newFiles.push(data);
                    resolve();
                }
            });
        });

        Promise.all(resizePromises).then(() => {
            setSelectedFiles(newFiles);
        }).catch(error => {
            console.error("Error resizing images: ", error);
        });
    };

    const resizeImage = (file, maxWidth, maxHeight, callback) => {
        const img = new window.Image(); // Use a classe Image nativa do JavaScript
        const reader = new FileReader();

        reader.onload = function (e) {
            img.src = e.target.result;

            img.onload = function () {
                const canvas = canvasRef.current;

                if (!canvas) {
                    console.error('Canvas não encontrado.');
                    return;
                }

                const ctx = canvas.getContext('2d');
                if (!ctx) {
                    console.error('Contexto 2D não disponível.');
                    return;
                }

                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round((height *= maxWidth / width));
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round((width *= maxHeight / height));
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);
                canvas.toBlob(callback, file.type);
            };
        };

        reader.readAsDataURL(file);
    };

    const handleFileRemove = (event) => {
        const files = selectedFiles;
        const index = files.indexOf(event.file);
        files.splice(index, 1);
        setSelectedFiles(selectedFiles);
    };

    const handlePaste = (event) => {
        const items = event.clipboardData.items;
        let newFiles = []; // Armazena os novos arquivos

        for (let i = 0; i < items.length; i++) {
            const item = items[i];

            if (item.kind === 'file') {
                const file = item.getAsFile();
                const objectURL = URL.createObjectURL(file);
                file.objectURL = objectURL;
                newFiles.push(file);
            }
        }

        if (newFiles.length > 0) {
            // Adiciona os novos arquivos à lista existente
            setSelectedFiles(prev => [...prev, ...newFiles]);

            if (fileUploadRef.current) {
                fileUploadRef.current.setFiles(prev => [...prev, ...newFiles]);

                if (fileUploadRef.current.props.onFileSelect) {
                    const fakeEvent = {
                        originalEvent: event,
                        files: newFiles,
                    };
                    fileUploadRef.current.props.onFileSelect(fakeEvent);
                }
            }
        }
    };

    useEffect(() => {
        // Adiciona o listener de colagem
        window.addEventListener('paste', handlePaste);

        return () => {
            window.removeEventListener('paste', handlePaste);
        };
    }, []);

    const nextStep1 = () => {

        if (!formik.values.requesterEmail || !formik.values.branchId) {
            formik.touched.requesterEmail = true;
            formik.touched.branchId = true;

            handleOnBlur("requesterEmail");
            handleOnBlur("branchId");
            toast.error("Por favor, preencha todos os campos obrigatórios")

            return false;
        } else {
            localStorage.setItem("email", formik.values.requesterEmail);
            localStorage.setItem("branch", formik.values.branchId)
            setStep(1);
        }
    }

    const nextStep2 = () => {
        const serviceId = formik.values.serviceTypeId;
        if (!serviceId) {
            formik.touched.serviceTypeId = true;
            handleOnBlur("serviceTypeId");
            toast.error("Por favor, preencha todos os campos obrigatórios")
            return false;
        } else {
            if (serviceId === sfa.id) {
                setStep(2);
            } else {
                formik.setValues(prev => ({...prev, title:setTitle(serviceId)}))
                setStep(3);
            }
        }
    }

    const setTitle = (serviceId) => {
        switch (serviceId) {
            case "cbfe9fba7c7f460ebaabac1dc70dd6e7":
                return "Cadastro de Acesso para usuário existente";
            case "12e13f0090e1439794b444141ee27048":
                return "Desativar Usuário";
            case "7ed4f955c7164d5682576ada5e7dcb7e":
                return "Cadastro de novo usuário";
            case "f7e4ffd001a2426c9ac47559b5ce5ae8":
                return "Liberação de Site";
            default:
                return ""
        }
    }

    const nextStep3 = () => {
        setStep(3)
    }

    const nextStepMotivo = (data, message, description) => {
        setStep(3)
        toast.info(message)
        formik.setValues(prev => ({
            ...prev,
            title: data,
            description: description
        }));
    }

    console.log(step)

    const nextStepArquivoCriptografado = () => {
        setStep(3);
        setDialogArquivoCriptografado(false);
        formik.setValues(prev => ({
            ...prev,
            title: "Erro no recebimento de carga - Arquivo de Carga deve estar criptografado",
            description: "Não consigo realizar a baixa da carga RX"
        }));
    }

    const prevStep = () => {
        setStep(1);
        formik.setValues(prev => ({
            ...prev,
            title: null,
            description: null
        }));
        setSelectedFiles([])
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            calledService.validate(formik.values);
        } catch (error) {
            touchedFormik();
            toast.error("Por favor, preencha todos os campos obrigatórios")
            setLoading(false);
            return false;
        }

        try {
            const response = await calledService.createCalled(formik.values);
            setCalled(response.data);

            if (selectedFiles.length > 0) {
                const formData = new FormData();
                for (let i = 0; i < selectedFiles.length; i++) {
                    formData.append('files', selectedFiles[i]);
                }

                await calledService.savedAttachment(response.data.id, formData);
                setIsRequestOpen(true);
                setLoading(false);
            } else {
                setLoading(false);
                setIsRequestOpen(true);
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };

    const touchedFormik = () => {
        const entradas = Object.entries(formik.values);

        entradas.forEach(([chave]) => {
            formik.touched[chave] = true
            handleOnBlur(chave);
        });

    }

    const handleOnBlur = (name) => {
        formik.setTouched({ ...formik.touched, [name]: true })
    }

    const updateContact = () => {
        employeeService.updateContact({
            id: user.userid,
            contact: contact,
            office: office
        }).then(response => {
            setEmployee(response.data);
        }).catch(error => {
            console.error(error);
        })
    }

    return (
        <div className="bg-called">
            {loading && <LoadingPage />}
            <div className="row">
                {employee.contact && employee.office ?
                    <Sidenav
                        title="Solicitação de Chamado"
                        subtitle="Preencha o formulário para abrir um chamado"
                        img={ImagemChamado} width={143}
                    />
                    :
                    <Sidenav
                        title="Solicitação de Chamado"
                        img={ImagemChamado}
                        subtitle={
                            <>
                                <label className="input-label">Forneça os seus dados nos campos abaixo para melhor atendimento.</label>
                                {!employee.contact &&
                                    <div>
                                        <Form>
                                            <label className="input-label">
                                                Whatsapp
                                            </label>
                                            <InputMask
                                                id="contact"
                                                name="contact"
                                                mask="(99) 99999-9999"
                                                className="input-text-sm"
                                                value={contact}
                                                onChange={e => setContact(e.target.value)}
                                            />
                                        </Form>
                                    </div>
                                }
                                {!employee.office &&
                                    <div>
                                        <Form>
                                            <InputForm label="Cargo"
                                                id="Office"
                                                name="office"
                                                value={office}
                                                onChange={e => setOffice(e.target.value)}
                                            />
                                        </Form>
                                    </div>
                                }
                                <div className="d-flex justify-content-left mt-4">
                                    <button type="button" className="btn btn-success" style={{ minWidth: "50%" }} onClick={() => updateContact()}>
                                        SALVAR
                                    </button>
                                </div>
                            </>
                        }
                    />
                }


                <div className="col-md-5">
                    {isRequestOpen ?
                        <Card className="card-called mat-elevation-z8 pl-6 pr-6 pb-3 pt-4">
                            <div>
                                <h6>Chamado registrado com sucesso! &#9989;</h6>
                                <p>A equipe de suporte já recebeu sua solicitação e em breve o chamado será atendido.</p>
                                <p>Anote o número dessa solicitação: <strong>{called.ticket}</strong></p><br />
                                <p><a href={window.innerWidth > 600 ? "/suporte/minhas-solicitacoes/#/" + called.ticket : "/suporte/minhas-solicitacoes/ticket/" + called.ticket}>Clique aqui para acompanhar o chamado ou entre no menu <strong>Minhas Solicitações</strong></a></p>
                                <br />
                                <a className="btn btn-primary" href="/suporte/solicitacao">Abrir novo chamado</a>
                            </div>
                        </Card>
                        :
                        <Card title={
                            <div className="called-info">
                                {authenticated ?
                                    <>
                                        <label
                                            className={((step === 1 || step === 2) && "info-active") + " non-selectable"}
                                            onClick={(formik.values.requesterEmail || formik.values.branchId ? () => setStep(1) : null)}>
                                            <i className="bi bi-1-circle-fill" /> Motivo da solicitação
                                        </label>
                                        <label
                                            className={(step === 3 && "info-active") + " non-selectable"}
                                            onClick={formik.values.serviceTypeId ? (() => setStep(3)) : null}>
                                            <i className="bi bi-2-circle-fill" /> Dados sobre a solicitação
                                        </label>
                                    </>
                                    :
                                    <>
                                        <label onClick={() => setStep(0)} className={(step === 0 && "info-active") + " non-selectable"}>
                                            <i className="bi bi-1-circle-fill" /> Seus dados
                                        </label>
                                        <label
                                            className={(step === 1 && "info-active") + " non-selectable"}
                                            onClick={(formik.values.requesterEmail || formik.values.branchId ? () => setStep(1) : null)}>
                                            <i className="bi bi-2-circle-fill" /> Motivo da solicitação
                                        </label>
                                        <label
                                            className={(step === 2 && "info-active") + " non-selectable"}
                                            onClick={formik.values.serviceTypeId ? (() => setStep(2)) : null}>
                                            <i className="bi bi-3-circle-fill" /> Dados sobre a solicitação
                                        </label>
                                    </>
                                }

                            </div>
                        }
                            className="card-called mat-elevation-z5 pl-6 pr-6 pb-3 pt-2">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        {((step === 0) && !authenticated) &&
                                            <div>
                                                <div className="col-md-12">
                                                    <Form id="requesterEmail">
                                                        <DropDown
                                                            id="dropRequesterEmail"
                                                            label="E-mail"
                                                            name="requesterEmail"
                                                            findBy="email"
                                                            filterBy="email"
                                                            optionValue="email"
                                                            label1="name"
                                                            label2="email"
                                                            label3="office"
                                                            icon="bi bi-person-circle"
                                                            value={formik.values.requesterEmail}
                                                            options={employees}
                                                            invalid={formik.touched.requesterEmail && formik.errors.requesterEmail}
                                                            onBlur={() => handleOnBlur("requesterEmail")}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form id="branchId">
                                                        <DropDown
                                                            id="DropBranchId"
                                                            name="branchId"
                                                            label="Filial"
                                                            label1="name"
                                                            optionValue="id"
                                                            findBy="name"
                                                            noFilter
                                                            value={formik.values.branchId}
                                                            options={branches}
                                                            invalid={formik.touched.branchId && formik.errors.branchId}
                                                            onBlur={() => handleOnBlur("branchId")}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </Form>
                                                </div>
                                                <div className="d-flex justify-content-center mb-4">
                                                    <button type="button" className="btn btn-primary ml-2 mt-5" style={{ minWidth: "50%" }} onClick={nextStep1}>
                                                        Próximo
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                        {step === 1 &&
                                            <>
                                                <div className="col-md-12">
                                                    <Form id="serviceTypeId">
                                                        <DropDown
                                                            id="dropServiceTypeId"
                                                            label="Tipo de Solicitação"
                                                            label1="description"
                                                            optionValue="id"
                                                            name="serviceTypeId"
                                                            findBy="description"
                                                            img
                                                            noFilter
                                                            value={formik.values.serviceTypeId}
                                                            options={serviceType} invalid={formik.touched.serviceTypeId && formik.errors.serviceTypeId}
                                                            onBlur={() => handleOnBlur("serviceTypeId")}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </Form>
                                                </div>
                                                {renderInput()}
                                                <div className="d-flex justify-content-center mb-4">
                                                    {!authenticated &&
                                                        <button type="button" className="btn btn-secondary mt-5" onClick={() => setStep(step - 1)} style={{ minWidth: "50%" }}                                             >
                                                            Voltar
                                                        </button>
                                                    }
                                                    <button type="button" className="btn btn-primary ml-2 mt-5" style={{ minWidth: "50%" }} onClick={nextStep2}>
                                                        Próximo
                                                    </button>
                                                </div>
                                            </>
                                        }
                                        {step === 2 &&
                                            <>
                                                <div className="error-sfa">
                                                    <label onClick={() => setDialogArquivoCriptografado(true)}>Erro no recebimento de arquivo  (Arquivo de Carga deve está criptografado)</label>
                                                    <Reason
                                                        label="Erro no envio de arquivo"
                                                        onClick={() => nextStepMotivo("Erro no envio de arquivo")} />
                                                    <Reason
                                                        label="Confirmação de arquivo recebido pelo faturamento"
                                                        onClick={() => nextStepMotivo("Confirmação de arquivo recebido pelo faturamento", "Informe o código da rota na descrição", "Solicito a confirmação do arquivo da rota: ")}
                                                    />
                                                    <Reason
                                                        label="Cliente não abre no ponto"
                                                        onClick={() => nextStepMotivo("Cliente não abre no ponto", "Insira o código do cliente ao descrever o problema", "Estou no ponto e as coordenadas do cliente não abre.\n\nCliente: ")}
                                                    />
                                                    <Reason
                                                        label="Habilitar nova captura de cliente"
                                                        onClick={() => nextStepMotivo("Habilitar nova captura de cliente", "Insira o código do cliente ao descrever o problema", "Solicito nova captura de coordenadas do cliente na próxima visita.\n\nCliente: ")}
                                                    />
                                                </div>
                                                <div className="d-flex justify-content-center mb-4">
                                                    <button type="button" className="btn btn-secondary mt-5" onClick={() => setStep(step - 1)} style={{ minWidth: "50%" }}                                             >
                                                        Voltar
                                                    </button>
                                                    <button type="button" className="btn btn-primary ml-2 mt-5" style={{ minWidth: "50%" }} onClick={nextStep3}>
                                                        Continuar
                                                    </button>
                                                </div>
                                            </>
                                        }
                                        {step === 3 &&

                                            <>
                                                <div className="col-md-12">
                                                    <Form id="title">
                                                        <InputForm
                                                            id="InputTitle"
                                                            type="text"
                                                            label="Assunto"
                                                            name="title"
                                                            maxLength={256}
                                                            invalid={formik.touched.title && formik.errors.title}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.title}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form id="description">
                                                        <label
                                                            className={
                                                                formik.touched.description && formik.errors.description
                                                                    ? "input-label i-error"
                                                                    : "input-label"
                                                            }
                                                            htmlFor="description"
                                                        >
                                                            Descreva com mais detalhes sua solicitação
                                                        </label>
                                                        <InputTextarea
                                                            id="InputDescription"
                                                            type="text"
                                                            label="Detalhes"
                                                            name="description"
                                                            maxLength={5000}
                                                            rows={4}
                                                            className={
                                                                formik.touched.description && formik.errors.description
                                                                    ? "p-invalid"
                                                                    : ""
                                                            }
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.description}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form id="priority">
                                                        <label className="input-label" htmlFor="type">
                                                            Prioridade
                                                        </label>
                                                        <Dropdown
                                                            id="DropPriority"
                                                            name="priority"
                                                            className="dropdown-custom"
                                                            style={{ width: "100%" }}
                                                            options={optionsPriority}
                                                            value={formik.values.priority}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form>
                                                        <div>
                                                            <label className="input-label">Anexe arquivo  <i className="text-secondary"> Opcional</i></label>
                                                            <canvas ref={canvasRef} style={{ display: 'none' }} />
                                                            <FileUpload
                                                                ref={fileUploadRef}
                                                                name="files"
                                                                chooseLabel="Selecionar"
                                                                auto
                                                                multiple
                                                                onRemove={handleFileRemove}
                                                                customUpload
                                                                uploadHandler={handleFileSelect}
                                                                emptyTemplate={
                                                                    <p className="m-0">
                                                                        Arraste e solte os arquivos aqui para fazer upload.
                                                                    </p>
                                                                }
                                                            />
                                                        </div>
                                                    </Form>
                                                </div>
                                                <div className="d-flex justify-content-center mb-4">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary mt-5"
                                                        onClick={prevStep}
                                                        style={{ minWidth: "50%" }}
                                                    >
                                                        Voltar
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary ml-2 mt-5"
                                                        style={{ minWidth: "50%" }}
                                                    >
                                                        Finalizar
                                                    </button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </form>
                        </Card>
                    }
                </div>
                <div className="col-md-2"></div>
            </div>
            <Dialog
                header="Erro de arquivo Criptografado"
                style={{ width: '92vw' }}
                className="d-flex"
                visible={dialogArquivoCriptogrado}
                onHide={() => setDialogArquivoCriptografado(false)}
                footer={
                    <div className="d-flex justify-content-center mb-2">

                        <button type="button" className="btn btn-primary" onClick={nextStepArquivoCriptografado}>
                            Continuar com chamado
                        </button>
                    </div>
                }
            >
                <div className="px-4">
                    <ArquivoCriptografado />
                </div>
            </Dialog>
            <Dialog
                header="Assinatura para E-mail"
                style={{ width: '98vw' }}
                className="d-flex"
                visible={dialogAssinatura}
                onHide={() => { setDialogAssinatura(false); formik.setValues(prev => ({ ...prev, serviceTypeId: null })) }}
            >
                <div className="px-2">
                    <Assinatura />
                </div>
            </Dialog>
        </div>
    );

};

export default AberturaChamado;

const Reason = ({ label, message, onClick }) => (
    <label onClick={onClick}>{label}</label>
);
