import ApiService from "../api";
import ValidationError from '../exception/ValidationError';

export default class CalledService extends ApiService {

    constructor() {
        super('/api/called')
    }

    createCalled(called) {
        return this.post('/', called);
    }

    getCalledById(id) {
        return this.get(`/${id}`)
    }

    getCalledByTicket(ticket) {
        return this.get(`/ticket/${ticket}?sender=${true}`)
    }

    getAttachments(file) {
        return this.getFile(`/${file}/images`)
    }

    downloadFile(file) {
        return this.getFile(`/${file}/download/file`)
    }

    getDownloadsAttachments(id) {
        return this.getFile(`/${id}/download/zip/files`)
    }

    getCalledGetEmployee(email) {
        return this.get(`/requester?email=${email}`)
    }

    savedAttachment(id, formData) {
        return this.post(`/attachments/${id}`, formData)
    }

    addAttchamentComment(id, employeeId, formData) {
        return this.post(`/${id}/attachments/employee/${employeeId}`, formData)
    }

    addComment(id, comment) {
        return this.post(`/${id}/comment`, comment)
    }

    validate(called) {
        const errors = [];

        if (!called.requesterEmail) {
            errors.push("Selecione o solicitante")
        }

        if (!called.description) {
            errors.push("Digite uma descrição")
        }
        if (!called.title) {
            errors.push("Digite o assunto")
        }
        if (!called.serviceTypeId) {
            errors.push("Selecione o tipo de serviço")
        }
        if (errors && errors.length > 0) {
            throw new ValidationError(errors);
        }

    }

    consult(filter) {
        let params = `?title=${filter.title}`

        if (filter.code) {
            params = `${params}&code=${filter.description}`
        }

        return this.get(params);
    }
}