
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import EmployeeService from "../../services/resource/employeeService";
import Form from "../../components/form/form";
import LOGO from '../../assets/york.png'
import Card from "../../components/card/card";
import InputForm from "../../components/input/input";
import './styles.css'
import ButtonLoading from "../../components/buttonLoading/buttonLoading";

function Login() {
    const service = new EmployeeService();
    const auth = useAuth();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const login = (event) => {
        event.preventDefault();
        setLoading(true);
        var emailConcat = ''
        if (!email.includes("@")) {
            emailConcat = email.trim() + '@distribuidorayork.com.br';
        } else {
            emailConcat = email;
        }
        service.authenticate({
            email: emailConcat,
            password: password
        }).then((response) => {
            auth.login(response.data, response.data.token);
            navigate('/suporte');
            setLoading(false);
        }).catch((error) => {
            setError(error.response.data.message);
            setLoading(false);

        })
    };

    useEffect(() => {
        setError('')
    }, [email, password])

    return (

        <div className="login">
            <Card className="login-card">
                <div className="d-flex justify-content-center">
                    <img className="logo" src={LOGO} alt='LOGO YORK' height={53}  width={180} />
                </div>
                <div className="col-lg-12">
                    <form onSubmit={login}>
                        <Form>
                            <InputForm
                                id="email"
                                type="text"
                                label="Nome de usuário ou e-mail"
                                name="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)} />
                        </Form>
                        <Form>
                            <InputForm
                                id="password"
                                type="password"
                                name="password"
                                label="Senha"
                                value={password}
                                onChange={e => setPassword(e.target.value)} />
                        </Form>
                        <label className="i-error mt-3">{error}</label>
                        <div className="text-left">
                            <a style={{ fontSize: '14px' }} className="pointer mt-2" onClick={() => navigate("/suporte/solicitar-nova-senha")}>Esqueceu a senha?</a>
                        </div>
                        <Form>
                            <ButtonLoading label="entrar" className="btn btn-primary" loading={loading}/>

                        </Form>
                    </form>

                </div>
            </Card>
        </div>
    )

}

export default Login;