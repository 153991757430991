import React from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import AberturaChamado from '../views/helpdesk/abertura-chamado';
import Login from '../views/login/login';
import AcessoNegado from '../views/acesso-negado/acesso-negado';
import PaginaNaoEncontrada from '../views/pagina-nao-encontrada/pagina-nao-encontrada';
import NavBar from '../components/navbar/navbar';
import EsqueceuSenha from '../views/usuario/esqueceu-senha';
import AlterarSenha from '../views/usuario/alterar-senha';
import { VisualizarChamado } from '../views/helpdesk/visualizar-chamado';
import LoadingPage from '../views/loading/loading';
import Home from '../views/home/home';
import Contatos from '../views/contatos/contatos';
import HomeYork from '../views/home/home-York';
import Footer from '../components/footer/footer';

const MinhasSolicitacoes = React.lazy(() => import('../views/helpdesk/minhas-solicitacoes'))
const MeusDados = React.lazy(() => import('../views/meusdados/meusdados'))


function Router() {

    const { authenticated } = useAuth();

    const PrivateRoute = () => {
        return authenticated === true ?
            <> <NavBar />  <Outlet /> <Footer /></> : <Navigate to="/suporte/login" />;
    };

    const NotAuthenticatedRoute = () => {
        return authenticated === false ?
            <Outlet /> : <Navigate to="/suporte" />;
    };

    return (
        <BrowserRouter>

            <Routes>
                <Route path="/" element={<HomeYork />} />
                <Route path="/suporte/acesso-negado" element={<AcessoNegado />} />
                <Route path="/suporte/contatos" element={<Contatos />} />

                <Route path='/*' element={<PaginaNaoEncontrada />} />
                <Route path="/suporte" element={<PrivateRoute />}>

                    <Route path="/suporte/dados" element={
                        <React.Suspense fallback={<LoadingPage />}>
                            <MeusDados />
                        </React.Suspense>
                    } />
                    <Route path="/suporte/minhas-solicitacoes/" element={
                        <React.Suspense fallback={<LoadingPage />}>
                            <MinhasSolicitacoes />
                        </React.Suspense>
                    } />

                    <Route path="/suporte/solicitacao" element={<AberturaChamado />} />
                    <Route path="/suporte" element={(window.innerWidth < 600) ? <Home /> : <AberturaChamado />} />

                    {window.innerWidth < 600 &&
                        <Route path="/suporte/minhas-solicitacoes/ticket/:calledTicket" element={<VisualizarChamado />} />
                    }
                </Route>
                <Route path="/" element={<NotAuthenticatedRoute />}>
                    <Route path="/suporte/login" element={<Login />} />
                    <Route path="/suporte/solicitar-nova-senha" element={<EsqueceuSenha />} />
                    <Route path="/suporte/modificar-senha/:id/:codigo" element={<AlterarSenha />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default Router;