
import React from "react";
import './styles.css'
import useCommentWebSocket from "../../utils/useCommentWebSocket ";

function Contatos() {
    useCommentWebSocket();
    return (
        <div className="d-flex row">
            <hr />
            <h4>Contatos de plantão</h4>
            <br />
            <br />
            <Card nome="Antônio" horario="Segunda a Sexta: 9h às 19h" telefone="86994189363" />
            <Card nome="Regenildo" horario="Segunda a Sexta: 7:30h às 17:30h" telefone="86994312693" />
            <Card nome="Daniel" horario="Ausente - Férias" telefone="8694206732" disabled={true} />
        </div>
    )
}

export default Contatos;

const Card = ({ nome, telefone, horario, disabled }) => {
    return (
        <div className="container">
            <div className="contact-card mat-elevation-z6">
                <div className="contact-name">{nome}</div>
                <div className="contact-hours">{horario}</div>

                {disabled ?
                    <button
                        disabled={disabled}
                        href={`https://api.whatsapp.com/send?phone=55${telefone}`}
                        className="btn btn-success whatsapp-button"
                        target="_blank">
                        <i className="bi bi-whatsapp" /> Enviar mensagem
                    </button>
                    :
                    <a
                        disabled={disabled}
                        href={`https://api.whatsapp.com/send?phone=55${telefone}`}
                        className="btn btn-success whatsapp-button"
                        target="_blank">
                        <i className="bi bi-whatsapp" /> Enviar mensagem
                    </a>
                }
            </div>
        </div>
    )
}

