import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';

import { Carousel } from 'primereact/carousel';
import { Skeleton } from 'primereact/skeleton';


export const VisualizarChamadoSkeleton = () => {
    const [showDetails, setShowDetails] = useState(
        localStorage.getItem('showDetails') === 'false' ? false : true
    );
    const [showRequester, setShowRequester] = useState(
        localStorage.getItem('showRequester') === 'false' ? false : true
    );
    const [showResponsibility, setShowResponsibility] = useState(
        localStorage.getItem('showResponsibility') === 'false' ? false : true
    );
    const [showAttachment, setShowAttachment] = useState(
        localStorage.getItem('showAttachment') === 'false' ? false : true
    );
    const [showDates, setShowDates] = useState(
        localStorage.getItem('showDates') === 'false' ? false : true
    );

    useEffect(() => {
        localStorage.setItem('showDetails', showDetails);
        localStorage.setItem('showRequester', showRequester);
        localStorage.setItem('showResponsibility', showResponsibility);
        localStorage.setItem('showAttachment', showAttachment);
        localStorage.setItem('showDates', showDates);
    }, [showDetails, showRequester, showResponsibility, showAttachment, showDates]);

    const imagesTemplate = () => {
        return (
            <>
                <Skeleton height='103px' width='140px' className='m-1' />
            </>
        )
    }

    return (
        <div className='col-md-7 border card-details'>
            <Accordion title={<>Informação do chamado #<Skeleton width='60px' height='18px'/></>} onShow={e => setShowDetails(!showDetails)} show={showDetails}>
                <Info title="Assunto" />
                <Info title="Criador" />
                <Info title="Licença" />
                <Info title="Tipo de serviço" />
                <Info title="Prioridade" />
                <Info title="Status" />
                <Info title="Detalhes" />
                <Info title="Solução Aplicada" />
            </Accordion>
            <Accordion title="Mídias" onShow={e => setShowAttachment(!showAttachment)} show={showAttachment}>
                <div className='attchaments'>
                    <Carousel value={['imagem1', 'imagem2', 'imagem3']} numVisible={3} numScroll={1} itemTemplate={imagesTemplate} responsiveOptions={[
                        {
                            breakpoint: '1400px',
                            numVisible: 3,
                            numScroll: 1
                        },
                        {
                            breakpoint: '1199px',
                            numVisible: 3,
                            numScroll: 1
                        },
                        {
                            breakpoint: '767px',
                            numVisible: 2,
                            numScroll: 1
                        },
                        {
                            breakpoint: '575px',
                            numVisible: 1,
                            numScroll: 1
                        }
                    ]} />
                </div>
            </Accordion>
            <Accordion title="Responsável" onShow={e => setShowResponsibility(!showResponsibility)} show={showResponsibility}>
                <div className='d-flex'>
                    <div className='profile-assignee col-1'>
                        <Skeleton shape="circle" size="40px" ></Skeleton>
                    </div>
                    <div className='d-flex row col-10 pl-3'>
                        <strong><Skeleton width="10rem" height='18px'></Skeleton></strong>
                        <label><Skeleton width="10rem" height='18px' ></Skeleton></label>
                    </div>
                </div>
            </Accordion>
            <Accordion title="Datas" onShow={e => setShowDates(!showDates)} show={showDates}>
                <Info title="Criado" />
                <Info title="Em execução" />
                <Info title="Resolvido" />
            </Accordion>
            <Accordion title="Solicitante" onShow={e => setShowRequester(!showRequester)} show={showRequester}>
                <div className='d-flex'>
                    <div className='profile-requester col-1'>
                        <Skeleton shape="circle" size="39px" ></Skeleton>
                    </div>
                    <div className='d-flex row col-11 pl-3'>
                        <strong><Skeleton width="10rem" height='18px'></Skeleton></strong>
                        <label><Skeleton width="10rem" height='18px'></Skeleton></label>
                    </div>
                </div>
            </Accordion>
        </div>
    );
};

const Accordion = (props) => {
    return (
        <div className='accordion'>
            <div className="accordion-ticket" onClick={props.onShow}>
                <h5>{props.title}</h5>
                <i className={'pi ' + (props.show ? 'pi-angle-down' : 'pi-angle-right')} />
            </div>
            {props.show &&
                <>
                    {props.children}
                </>
            }
        </div>
    )
}

const Info = (props) => {
    return (
        <div className="data-info">
            <span className="data-info-title">{props.title}: </span>
            <span className="data-info-content"><Skeleton width="8rem" height='18px'></Skeleton></span>
        </div>
    )
}