import { useEffect } from 'react'; // Ajuste o caminho conforme necessário
import { useAuth } from '../context/authContext';
import LOGO from '../assets/profile.jpg'
import { useWebSocket } from '../services/useWebSocket';

const useCommentWebSocket = () => {
    const { user } = useAuth();
    const { connectWebSocket, isConnect, stompClient } = useWebSocket();

    useEffect(() => {
        connectWebSocket();

        return () => {
            if (stompClient && !isConnect) {
                stompClient.disconnect();
            }
        };
    }, []);

    useEffect(() => {
        if (isConnect && stompClient) {
            stompClient.subscribe(`/comment/user/${user.sub}`, (message) => {
                const newComment = JSON.parse(message.body);
             
                if ((newComment.userEmail === user.sub) === false) {
                    if (Notification.permission === "granted") {

                        const notification = new Notification((newComment.userName + " enviou uma mensagem para você:"), {
                            body: newComment.comment,
                            icon: LOGO

                        })

                        notification.onclick = function () {
                            window.open("/suporte/minhas-solicitacoes/#/" + newComment.calledTicket, '_blank')
                        };
                    }
                }
            });
        }
    }, [stompClient, isConnect]);
};

export default useCommentWebSocket;
