import ApiService from "../api";
import ValidationError from '../exception/ValidationError';

export default class BranchService extends ApiService {

    constructor(){
        super('/api/branches')
    }

    findAll(){
        return this.get(`/`)
    }

}