
import React from "react";
import { useNavigate } from "react-router-dom";
import './styles.css'

function Home() {
    return (

        <div className="d-flex row">
            <hr />
            <h5>Bem-vindo(a) ao Helpdesk York! </h5>
            <br />
            <br />
            <div className="d-flex">
                <CardImage title="Nova solicitação" icon="bi bi-pencil-square" href="solicitacao" />
                <CardImage title="Minhas solicitações" icon="bi bi-journal-text" href="minhas-solicitacoes" />
                <CardImage title="Contatos de plantão" icon="bi bi-whatsapp" href="contatos" />
            </div>

        </div>
    )

}

export default Home;

const CardImage = ({ title, icon, href }) => {
    const navigate = useNavigate();

    return (
        <div className="col-4 col-md-1 card-home mat-elevation-z6" onClick={() => navigate(href)}>
            <div className="card-home-title">
                {title}
            </div>
            <div className="card-home-image">
                <i className={icon} />
            </div>
        </div>
    )
}