import React from 'react';
import './styles.css'

class ButtonLoading extends React.Component {
    render() {
        return (
            <button type={this.props.type} style={this.props.style} className={this.props.className} disabled={this.props.loading}>
                {this.props.label}
                {this.props.loading &&
                    <i className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true" />
                }
            </button>
        )
    }

}

export default ButtonLoading;