
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import EmployeeService from "../../services/resource/employeeService";
import Form from "../../components/form/form";
import LOGO from '../../assets/york.png'
import Card from "../../components/card/card";
import InputForm from "../../components/input/input";

import './styles.css'

function AlterarSenha() {
    const service = new EmployeeService();
    const { id, codigo } = useParams();
    const navigate = useNavigate();

    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [error, setError] = useState('');

    const resetPassword = (event) => {
        event.preventDefault();

        service.resetPassword({
            id: id,
            newPassword: password,
            verificationCode: codigo,
        }).then(() => {
            toast.success("Senha alterada com sucesso, você já pode realizar login com nova senha!");
            navigate('/suporte/login');
        }).catch(() => {
            setError("Erro ao modificar senha")
        })
    };

    return (

        <div className="login">
            <Card className="login-card">
                <div className="d-flex justify-content-center mb-3">
                    <img className="logo" src={LOGO} alt='LOGO YORK' height={53} width={180} />
                </div>
                <div className="col-lg-12">
                    <form onSubmit={resetPassword}>
                        <Form>
                            <InputForm
                                id="password"
                                type="password"
                                name="password"
                                label="Senha"
                                value={password}
                                onChange={e => setPassword(e.target.value)} />
                        </Form>
                        <Form>
                            <InputForm
                                id="passwordRepeat"
                                type="password"
                                name="passwordRepeat"
                                label="Repita nova senha"
                                value={passwordRepeat}
                                onChange={e => setPasswordRepeat(e.target.value)} />
                        </Form>
                        <label className="i-error mt-3">{error}</label>
                        <Form>
                            <button type="submit" className="btn btn-primary " disabled={(!password && !passwordRepeat) || (password !== passwordRepeat)} >
                                ALTERAR SENHA
                            </button>
                        </Form>
                    </form>

                </div>
            </Card>
        </div>
    )

}

export default AlterarSenha;