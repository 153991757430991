import { useState } from 'react';
import GIF from '../../assets/Ativar-notificação.gif'
import './styles.css'

function HabilitarNotificacao() {
    const [time, setTime] = useState(true);
    setTimeout(() => {
        setTime(false)
    }, 8500);
    return (
        <>
        {time &&
        <div className='mat-elevation-z6  notificacao-gif'>
            <img className='' src={GIF} width={250} height={250} alt='Exemplo de como habilitar notificação'/>
            <label className='text-center'>Habilite as notificações!</label>
            </div>  
            }
        </>
    )
}

export default HabilitarNotificacao;