import React from 'react'
import './styles.css'

function Form(props) {
    return (
        <div className='form-group'>
            <div>
                <label className="input-label">
                    {props.label}
                </label>
            </div>
            {props.children}
        </div>
    )
}

export default Form;